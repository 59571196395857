//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// TODO: remove upseller DOM elements for Lightspeed/Medituote
import canonicalUrls from '~/mixins/canonicalUrls';
import {createEventData} from "@/utils/eventUtils";

export default {
    middleware: ['loginRedirect'],
    mixins: [canonicalUrls],
    async mounted() {
        await this.sendPageViewEvent();
    },
    async asyncData(context) {
        const { error, $axios, route, i18n, store } = context;
        let path = route.params.pathMatch;
        if (!path) {
            path = 'home';
        }

        if (process.server) {
            const { req, store } = context;
            let ipAddress = req.connection.remoteAddress || null;
            if (req.headers['x-forwarded-for']) {
                // Split the string and get the first (leftmost) IP
                ipAddress = req.headers['x-forwarded-for'].split(',')[0].trim();
            }

            let userAgent = req.headers['user-agent'];

            // Build the URL
            const protocol = req.headers['x-forwarded-proto'] || req.protocol;
            const host = req.headers.host;
            const path = req.url;
            let currentUrl = `${protocol}://${host}${path}`;

            await store.dispatch('userInfo/setUserInfo', { userAgent, ipAddress, currentUrl });
        }

        try {
            return await $axios.$get(`/route/fi/${path}`);
        } catch (e) {
            return error({ statusCode: 404, message: 'Page not found' });
        }
        await this.$store.dispatch('upsy/update');
    },
    methods: {
        async sendPageViewEvent() {
            let userData = null;
            const fbc = this.$storage.getCookie('_fbc');
            if (fbc) {
                userData = {
                    fbc: fbc,
                };
            }

            const fbp = this.$storage.getCookie('_fbp');
            if (fbp) {
                userData = {
                    ...userData,
                    fbp: fbp,
                };
            }

            if (this.$store.state.auth.loggedIn) {
                let user = this.$store.state.auth.user;
                if (user) {
                    userData = {
                        ...userData,
                        em: this.$store.state.auth.user.email,
                        fn: this.$store.state.auth.user.firstname,
                        ln: this.$store.state.auth.user.lastname,
                    }
                }
            }

            const eventData = createEventData(
                'PageView',
                this.$store.state.userInfo.currentUrl,
                {},
                userData
            );
            await this.$store.dispatch('eventTracking/sendEventData', { eventData });
        }
    },
    head(context) {
        const metaFields = context?.cmsRoute?.fields?.meta?.fields;
        const title = metaFields?.title || this.$t('index.title');
        const meta = [];
        if (metaFields?.ogTitle) {
            meta.push({
                hid: 'og:title',
                name: 'og:title',
                content: metaFields.ogTitle,
            });
        } else {
            meta.push({
                hid: 'og:title',
                name: 'og:title',
                content: this.$t('index.title'),
            });
        }
        if (metaFields?.ogDescription) {
            meta.push({
                hid: 'og:description',
                name: 'og:description',
                content: metaFields.ogDescription,
            });
            meta.push({
                hid: 'description',
                name: 'description',
                content: metaFields.ogDescription,
            });
        }
        if (metaFields?.keywords) {
            meta.push({
                hid: 'keywords',
                name: 'keywords',
                content: metaFields.keywords,
            });
        }
        if (metaFields?.ogImage?.fields?.file?.url) {
            meta.push({
                hid: 'og:image',
                name: 'og:image',
                content: metaFields.ogImage.fields.file.url,
            });
        }

        return {
            title,
            meta,
            link: this.head.link,
        };
    },
};
