//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import QuantitySelector from '../modules/opus/components/QuantitySelector';
import merge from 'lodash.merge';
import htmlParser from '~/mixins/htmlParser';
import {createEventData} from "@/utils/eventUtils";

export default {
    components: { QuantitySelector },
    mixins: [htmlParser],
    async fetch() {
        const {
            data: {
                products: { items },
            },
        } = await this.$axios.$get(`/product/${this.urlKey}`);
        const {
            data: {
                products: { items: stockItems },
            },
        } = await this.$axios.$get(`/product/stock/${this.urlKey}`);

        const item = items[0];
        const stock = stockItems[0];

        if (!item) {
            this.$nuxt.error({ statusCode: 404, message: 'Product not found' });
            throw new Error('Product not found');
        }

        const product = item && stock ? merge(item, stock) : item;
        this.product = product;
        this.pimage = product.small_image.url;
        try {
            const skus = this.$storage.getCookie('recently_viewed');
            if (!skus) {
                const sku = [this.product.sku];
                this.$storage.setCookie('recently_viewed', sku);
            } else if (!skus.includes(this.product.sku)) {
                skus.push(this.product.sku);
                this.$storage.setCookie('recently_viewed', skus);
            }
        } catch (e) {
            console.error(e);
        }

        if (this.$gtm) {
            try {
                this.$gtm.push({ ecommerce: null, items: null });
                this.$gtm.push({
                    event: 'view_item',
                    ecommerce: {
                        currency: this.product.price_range.minimum_price.final_price.currency,
                        items: [
                            {
                                item_id: this.product.sku,
                                item_name: this.product.name,
                                price: this.product.price_range.minimum_price.final_price.value,
                            },
                        ],
                    },
                    value: this.product.price_range.minimum_price.final_price.value,
                    items: [
                        {
                            id: this.product.sku,
                            google_business_vertical: 'retail',
                        },
                    ],
                });
            } catch (e) {
                console.error(e);
            }
        }

        await this.$store.dispatch('upsy/update');
        if (process.client) {
            await this.sendViewContentEventData();
        }
    },
    data() {
        return {
            product: null,
            pimage: null,
            review: false,
            backPath: '/',
            tagTypeValue: 'h3',
        };
    },
    async mounted() {
        this.$root.$on('candidateProduct', this.replaceProduct);
        if (process.client) {
            this.tagTypeValue = document.querySelectorAll('h1').length ? 'h3' : 'h1';
            if (this.product) {
                await this.sendViewContentEventData();
            }
        }
    },
    created() {
        if (this.$nuxt?.context?.from?.path) {
            this.backPath = this.$nuxt.context.from.path;
        }
    },
    methods: {
        /* Replace mediagallery and description of a chosen simpleitem*/
        replaceProduct(product) {
            this.product.description = product.product.description;
            this.product.media_gallery = product.product.media_gallery;
            this.product.short_description = product.product.short_description;
            this.product.stock_status = product.product.stock_status;
            this.product.id = product.product.id;
        },
        scrollToDescription() {
            this.$scrollTo('#description-section');
        },
        async sendViewContentEventData() {
            try {
                let userData = null;
                const fbc = this.$storage.getCookie('_fbc');
                if (fbc) {
                    userData = {
                        fbc: fbc,
                    };
                }

                const fbp = this.$storage.getCookie('_fbp');
                if (fbp) {
                    userData = {
                        ...userData,
                        fbp: fbp,
                    };
                }

                if (this.$store.state.auth.loggedIn) {
                    let user = this.$store.state.auth.user;
                    if (user) {
                        userData = {
                            ...userData,
                            em: this.$store.state.auth.user.email,
                            fn: this.$store.state.auth.user.firstname,
                            ln: this.$store.state.auth.user.lastname,
                        }
                    }
                }

                const eventData = createEventData(
                    'ViewContent',
                    null,
                    {
                        contents: [
                            {
                                id: this.product.sku,
                                quantity: 1,
                                item_price: this.product.price_range.minimum_price.final_price.value,
                            }
                        ],
                        content_type: 'product',
                        value: this.product.price_range.minimum_price.final_price.value,
                        currency: 'EUR',
                        content_ids: [this.product.sku],
                    },
                    userData
                );
                await this.$store.dispatch('eventTracking/sendEventData', {eventData});
            } catch (e) {
                console.error(e);
            }
        },
    },
    computed: {
        urlKey() {
            const path = this.$route.params.pathMatch;
            const navigationRoute = path.split('/');
            const urlKey = navigationRoute.length > 1 ? navigationRoute[navigationRoute.length - 1] : path;

            return urlKey;
        },
        shortenedDescription() {
            try {
                if (process.client) {
                    const desc = document.createElement('div');
                    const final = document.createElement('div');

                    desc.innerHTML = this.product.description.html;
                    final.appendChild(desc.firstChild);
                    return final.innerHTML;
                } else {
                    return this.parseTextFromHTML(this.product.description.html) || '';
                }
            } catch (e) {
                return this.product.description.html;
            }
        },
        shortenedDescriptionTextContent() {
            try {
                const desc = document.createElement('div');
                const final = document.createElement('div');

                desc.innerHTML = this.product.description.html;
                final.appendChild(desc.firstChild);
                return final.textContent;
            } catch (e) {
                return this.product.description.html.replace(/(<([^>]+)>)/gi, '');
            }
        },
        shortDescription() {
            try {
                if (process.client) {
                    const html = this.product.short_description.html;
                    const desc = document.createElement('div');
                    desc.innerHTML = html;
                    const li = [...desc.querySelectorAll('li')];
                    const classes = ['py-2', 'px-4', 'odd:bg-gray-100', 'even:bg-white', 'flex', 'items-center'];
                    li.forEach((item) => {
                        item.classList.add(...classes);
                        const text = document.createElement('span');
                        text.innerHTML = item.textContent;
                        const icon = document.createElement('svg');
                        icon.innerHTML =
                            '<svg class="text-primary mr-4" width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                            '  <path d="M2 5L5 8L11 2" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                            '</svg>\n';
                        item.innerHTML = '';
                        item.appendChild(icon.firstChild);
                        item.appendChild(text);
                    });
                    const notify = desc.querySelector('.huom');
                    if (!notify) {
                        return desc.innerHTML;
                    }
                    notify.removeChild(notify.firstChild);
                    notify.classList.remove(...classes);
                    notify.classList.add('bg-[#8b69ff1a]', 'p-4', 'mt-4', 'border-[#8B69FF]', 'bg-[#8b69ff1a]', 'rounded-md', 'border');
                    return desc.innerHTML;
                }
            } catch (e) {
                console.error(e);
            }
        },
        brand() {
            if (this.product) {
                try {
                    const value = this.product.custom_attributes.find((attribute) => attribute.attribute_metadata.code === 'brand');

                    return value?.selected_attribute_options?.attribute_option[0].label;
                } catch (e) {}
            }
        },
        /**
         * Find the breadcrumbs that has the most categories
         * @returns {*|*[]}
         */
        category() {
            if (this.product) {
                let len = 0;
                let result = null;
                [...this.product.categories].forEach((item) => {
                    if (item.breadcrumbs?.length && len < item.breadcrumbs?.length) {
                        len = item.breadcrumbs?.length;
                        result = item;
                    }
                });

                if (result) {
                    const lastBreadcrumb = result.breadcrumbs[result.breadcrumbs.length - 1],
                        lastBreadcrumbUid = lastBreadcrumb.category_uid;
                    if (lastBreadcrumbUid !== result.uid) {
                        result.breadcrumbs.push({
                            category_uid: result.uid,
                            category_level: lastBreadcrumb.category_level + 1,
                            category_name: result.name,
                            category_url_path: result.url_path,
                        });
                    }
                }

                return result ? result.breadcrumbs : [];
            }
        },
        crossSellProducts() {
            return this.product.crosssell_products && this.product.crosssell_products.length;
        },
        protectionLevel() {
            try {
                const [data] = [...this.product.custom_attributes].filter((attribute) => attribute['attribute_metadata'].code === 'protection_level');

                return data?.selected_attribute_options?.attribute_option[0].label;
            } catch (e) {}
            return undefined;
        },
    },
    head() {
        function starCount(items) {
            const len = [...items].length;
            const total = [...items].reduce((acc, curr) => {
                const rating = curr.average_rating / 20;
                acc += rating;

                return acc;
            }, 0);

            return parseInt((total / len).toFixed(0)) || 0;
        }

        if (!this.product) {
            return;
        }
        const reviews = this.product.reviews.items;
        const review = reviews.reduce((acc, curr) => {
            const obj = {};
            obj['@type'] = 'Review';
            obj.author = {
                '@type': 'Person',
                name: curr.nickname,
            };
            obj.name = curr.summary;
            obj.description = curr.text;
            const ratingsBreakdown = curr.ratings_breakdown.map((review) => Number(review.value));
            obj.reviewRating = {
                '@type': 'Rating',
                ratingValue: (ratingsBreakdown.reduce((a, b) => a + b, 0) / ratingsBreakdown.length).toFixed(3),
                bestRating: '5',
                worstRating: '0',
            };

            acc.push(obj);

            return acc;
        }, []);

        const aggregateRating =
            this.product.review_count > 0
                ? {
                      '@type': 'AggregateRating',
                      ratingValue: starCount(this.product.reviews.items),
                      ratingCount: this.product.review_count,
                      bestRating: '5',
                      worstRating: '0',
                  }
                : null; // Google accepts only positive value in ratingCount

        const structuredDataScript =
            review.length > 0 || this.product.review_count > 0
                ? [
                      {
                          type: 'application/ld+json',
                          json: {
                              '@context': 'https://schema.org',
                              '@type': 'Product',
                              name: this.product.name,
                              description: !this.$fetchState.pending && !this.$fetchState.error && this.shortenedDescriptionTextContent,
                              image: this.product.thumbnail.url,
                              review,
                              aggregateRating,
                          },
                      },
                  ]
                : [];
        // Google requires 'Either "offers", "review", or "aggregateRating" should be specified',
        // so if no reviews or rating - don't add structured data

        return {
            title: this.product.name,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: !this.$fetchState.pending && !this.$fetchState.error && this.shortenedDescriptionTextContent,
                },
                {
                    hid: 'og:description',
                    name: 'og:description',
                    content: !this.$fetchState.pending && !this.$fetchState.error && this.shortenedDescriptionTextContent,
                },
                {
                    hid: 'og:title',
                    name: 'og:title',
                    content: this.product.name,
                },
                {
                    hid: 'og:image',
                    name: 'og:image',
                    content: !this.$fetchState.pending && !this.$fetchState.error && this.product.thumbnail.url,
                },
            ],
            link: [{ rel: 'preload', as: 'image', href: this.product.thumbnail.url }],
            script: structuredDataScript,
        };
    },
    fetchKey: 'product-page',
};
